import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import App from './App.vue'
import VueRouter from 'vue-router'
import routers from './router/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
 axios.defaults.baseURL='/api'
//axios.defaults.baseURL=process.env.VUE_APP_URL

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token");
        if(token !== undefined && token != null){
            token && (config.headers.token = token);
        }
        return config;
    },
    error => {
      return Promise.error(error);
    })

axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.data.code === 203) {
            localStorage.removeItem("token")
            router.push({
                path: '/Login',
            })
            return Promise.reject(response);
        } else {
            return Promise.resolve(response);
        }
    }
)


Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(VueRouter);
Vue.use(VueAxios,axios);
const router = new VueRouter({
    scrollBehavior(to,from,saveTop){ if(saveTop){ return saveTop; }else{ return {x:0,y:0} } },
    mode: 'history',
    routes: routers
})
new Vue({
  render: h => h(App),
  router
}).$mount('#app')


