const routers = [
    {
        path: '/',
        name: "首页",
        component: () => import("@/components/HomePage"),
    },
    {
        path: '/Login',
        name: "登录页",
        component: () => import("@/components/Login"),
    },
    {
        path: '/OrderList',
        name: "订单列表",
        component: () => import("@/components/OrderList"),
    },
    {
        path: '/CompleteOrder',
        name: "完成订单",
        component: () => import("@/components/CompleteOrder"),
    },
    {
        path: '/OrderManage',
        name: "订单管理",
        component: () => import("@/components/OrderManage"),
    },
    {
        path: '/DeviceManage',
        name: "设备管理",
        component: () => import("@/components/DeviceManage"),
    },
    {
        path: '/MyDevice',
        name: "我的设备",
        component: () => import("@/components/MyDevice"),
    },
    {
        path: '/QCode',
        name: "扫码测试",
        component: () => import("@/components/QCode"),
    },
    {
        path: '/SubHistory',
        name: "转借记录",
        component: () => import("@/components/SubHistory"),
    },
    {
        path: '/EntryList',
        name: "入职申请",
        component: () => import("@/components/Entry/Index"),
    },
    {
        path: '/EntryAdd',
        name: "新增申请",
        component: () => import("@/components/Entry/Add"),
    },
    {
        path: '/EntryAdd/Success',
        name: "提交成功",
        component: () => import("@/components/Entry/Success"),
    }
]

export default routers
